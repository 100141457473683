﻿$default:(
    navbarBackgroundColor: #732E81,
    primaryColor: #3D9294,
    searchColor: #3D9294,
    sideMenuSubtitleColor: #732E81,
    linkColor: #732E81,
    secondaryColor: #C58ED0,
    warningColor: #953BA7,
    featuredPost: #f39c12
);

$mymarionnaud:(
    navbarBackgroundColor: #732E81,
    primaryColor: #3D9294,
    searchColor: #3D9294,
    sideMenuSubtitleColor: #732E81,
    linkColor: #732E81,
    secondaryColor: #C58ED0,
    warningColor: #953BA7,
    featuredPost: #f39c12
);

$sameday:(
    navbarBackgroundColor: #732E81,
    primaryColor: #3D9294,
    searchColor: #3D9294,
    sideMenuSubtitleColor: #732E81,
    linkColor: #732E81,
    secondaryColor: #C58ED0,
    warningColor: #953BA7,
    featuredPost: #f39c12
);